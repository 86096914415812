import axios from "axios";

const httpDataStatus = {
    SUCCESS: "SUCCESS",
    ERROR: "ERROR"
}

const BaseUrl = process.env.REACT_APP_BASE_URL;
//const BaseUrl = "http://localhost:8444/admin-api";

const mpaasHttp = {
    get: (_url: string, params?: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            let completeUrl = BaseUrl + _url;
            axios
                .get(completeUrl, { params })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    reject(error.data);
                });
        });
    },

    post: (_url: string, requestBody: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            let completeUrl = BaseUrl + _url;
            axios
                .post<any>(completeUrl, requestBody)
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    if(error.response.status == 403 && error.response.data == 'Forbidden'){
                        reject(error.response)
                    }else{
                        reject(error.data);
                    }
                });
        });
    },

    put: (_url: string, requestBody: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            let completeUrl = BaseUrl + _url;
            axios
                .put<any>(completeUrl, requestBody)
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    if(error.response.status == 403 && error.response.data == 'Forbidden'){
                        reject(error.response)
                    }else{
                        reject(error.data);
                    }
                });
        });
    },
    download: (_url: string) => {
        let completeUrl = BaseUrl + _url;
        window.location.href = completeUrl;
    }
};

export { mpaasHttp, httpDataStatus };
