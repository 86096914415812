import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AiOutlineHome } from "react-icons/ai";
import { Link } from "react-router-dom";
import { NewSoftwareForMacModel, ProhibitedSoftwaresListModel } from './ProhibitedSoftwares.model';
import { PLATFORM, LOADING_STATE } from './ProhibitedSoftwares.constants';
import ProhibitedSoftwaresStore from './ProhibitedSoftwares.store';
import './prohibitedSoftwares.css';
import { mpaasHttpAction } from '../../../utils/MpaasHttpActionHandler';

const ProhibitedSoftwares: React.FC = () => {

    const newSoftwaresForMacInitState = {name: "", executable: ""};

    const [currentOs, setCurrentOs] = useState<string>(PLATFORM.WINDOWS);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [newSoftwaresForWin, setNewSoftwaresForWin] = useState<string>("");
    const [newSoftwaresForMac, setNewSoftwaresForMac] = useState<NewSoftwareForMacModel>(newSoftwaresForMacInitState);
    const [prohibitedSoftwaresList, setProhibitedSoftwaresList] = useState<ProhibitedSoftwaresListModel[]>([]);
    const [loadingState, setLoadingState] = useState({status: false, type: ""})
    const [errorToast, setToast] = useState(false);
    const [errorMsg, bindError] = useState("");


    const resetProhibitedSoftwaresList = () => {
        setProhibitedSoftwaresList(prohibitedSoftwaresList.map(item => ({...item, isChecked: false})));
    }

    const handleOsToggle = (os: string) => {
        if (os === currentOs) return;

        os === PLATFORM.WINDOWS ? setNewSoftwaresForWin("") : setNewSoftwaresForMac(newSoftwaresForMacInitState);
        setIsEditMode(false);
        setProhibitedSoftwaresList([]);
        setCurrentOs(os);
    }

    const handleRemoveProhibitedSoftwares = () => {
        const deletedItems = prohibitedSoftwaresList.reduce((acc: any, softwareObj: ProhibitedSoftwaresListModel) => {
            if (softwareObj.isChecked) 
                acc.push({name: softwareObj.name, executable: softwareObj.executable});
            return acc;
        }, []);

        let payload = {
            prohibitedSoftwares: deletedItems,
            platform: currentOs
        }

        setLoadingState({status: true, type: LOADING_STATE.REMOVE})

        ProhibitedSoftwaresStore.updateProhibitedSoftwares(payload)
        .then((response: any) => {
            alert(response.message);
            setLoadingState({status: false, type: ""});
            return ProhibitedSoftwaresStore.fetchProhibitedSoftwares(currentOs);
        }).then((response: any) => {
            setIsEditMode(false);
            setProhibitedSoftwaresList(ProhibitedSoftwaresStore.makeProhibitedSoftwareList(response.data, currentOs));
        }).catch((error) => {
            setLoadingState({status: false, type: ""});
            if(error.status == 403 && error.data == 'Forbidden'){
                bindError('Requested Parameter contains malicious content');
            }else{
                bindError(mpaasHttpAction.makeErrorData(error?.error?.message, {...error, showAlert: true}).message);
            }
            _toastbar();
        });
    }

    const handleToggleEditProhibitedSoftwares = () => {
        if (isEditMode) {
            setIsEditMode(false);
            return;
        }

        resetProhibitedSoftwaresList();
        setIsEditMode(true);
    }

    const handleSaveNewSoftwares = () => {
        let payload;

        if (currentOs === PLATFORM.WINDOWS && newSoftwaresForWin.trim().length > 0) {
            payload = {
                prohibitedSoftwares: newSoftwaresForWin.split(',').map(item => ({name: "", executable: item.trim()})),
                platform: currentOs
            };
            setNewSoftwaresForWin('');
        } else if (currentOs === PLATFORM.MAC && newSoftwaresForMac.name.trim().length > 0 && newSoftwaresForMac.executable.trim().length > 0){
            if (!ProhibitedSoftwaresStore.isSaveMacPayloadValid(newSoftwaresForMac.name.trim(), newSoftwaresForMac.executable.trim())) {
                return;
            }
            payload = {
                prohibitedSoftwares: [{name: newSoftwaresForMac.name.trim(), executable: newSoftwaresForMac.executable.trim()}],
                platform: currentOs
            };
            setNewSoftwaresForMac(newSoftwaresForMacInitState);
        }

        if (!payload) {
            alert("Input fields can not be empty.");
            return;
        }

        setLoadingState({status: true, type: LOADING_STATE.SAVE})

        ProhibitedSoftwaresStore.saveProhibitedSoftwares(payload)
        .then((response: any) => {
            alert(response.message);
            setLoadingState({status: false, type: ""})
            return ProhibitedSoftwaresStore.fetchProhibitedSoftwares(currentOs);
        }).then((response: any) => {
            setProhibitedSoftwaresList(ProhibitedSoftwaresStore.makeProhibitedSoftwareList(response.data, currentOs));
        }).catch((error) => {
            setLoadingState({status: false, type: ""})
            
            if(error.status == 403 && error.data == 'Forbidden'){
                bindError('Requested Parameter contains malicious content');
            }else{
                bindError(mpaasHttpAction.makeErrorData(error?.error?.message, {...error, showAlert: true}).message);
            }
            _toastbar();
        });
    }

    const handleCheckboxChange = (checkboxId: number) => {
        const updatedCheckboxes = prohibitedSoftwaresList.map((currSoftwareObj) =>
        currSoftwareObj.id === checkboxId ? { ...currSoftwareObj, isChecked: !currSoftwareObj.isChecked } : currSoftwareObj
      );
      setProhibitedSoftwaresList(updatedCheckboxes);
    };

    const _toastbar = () => {
        setToast(true);
        setTimeout(function () {
            setToast(false);
        }, 4000);
    }

    useEffect(() => {
        ProhibitedSoftwaresStore.fetchProhibitedSoftwares(currentOs)
        .then((response: any) => {
            setProhibitedSoftwaresList(ProhibitedSoftwaresStore.makeProhibitedSoftwareList(response.data, currentOs));
        }).catch((error) => {
            mpaasHttpAction.error(mpaasHttpAction.makeErrorData(error?.error?.message, {...error, showAlert: true}));
        })
    }, [currentOs])

    return (
        <div>
            <div className='breadcrumb-section'>
                <AiOutlineHome />
                <span className="small"> &gt; <Link to="/settings" className="text-muted">Settings</Link> </span>
                <span className="small"> &gt; <span className="text-muted">Prohibited Softwares</span> </span>
            </div>
            {
                errorToast ?
                    <div className='toast-bar'>
                        <span>{errorMsg}</span>
                    </div> : ''
            }
            <div className='mt-3'>
                <h5>Configure Prohibited Softwares</h5>
                <p className="text-muted small">You can define and configure prohibited softwares for Windows & Mac</p>
            </div>

            <div className='bg-sky-lighter d-inline-block p-1 mt-3'>
                <Button
                    variant='light'
                    className={'px-4 ' + (currentOs === PLATFORM.WINDOWS ? 'bg-white shadow-sm' : '')}
                    onClick={() => handleOsToggle(PLATFORM.WINDOWS)}>
                    MS Windows
                </Button>

                <Button
                    variant='light'
                    className={'px-4 ' + (currentOs === PLATFORM.MAC ? 'bg-white shadow-sm' : '')}
                    onClick={() => handleOsToggle(PLATFORM.MAC)}>
                    Apple Mac
                </Button>
            </div>

            <div className='mt-4'>
                <div className='d-flex justify-content-between align-items-center prohibited-softwares-list-header'>
                    <p className='mb-0'>Prohibited Softwares</p>
                    <div>
                        {isEditMode && prohibitedSoftwaresList.some(softwareObj => softwareObj.isChecked) && (
                            <Button variant='link' className='text-danger' onClick={handleRemoveProhibitedSoftwares} disabled={loadingState.status && loadingState.type === LOADING_STATE.REMOVE}>{(loadingState.status && loadingState.type === LOADING_STATE.REMOVE) ? "Removing..." : "Remove"}</Button>
                        )}
                        <Button variant='link' className='ml-1 text-primary' onClick={handleToggleEditProhibitedSoftwares}>{isEditMode ? 'Cancel' : 'Edit'}</Button>
                    </div>
                </div>

                <div className='mt-3'>
                    <ul className='list-group border border-quill-lightest py-2 prohibited-softwares-list-section'>
                        {prohibitedSoftwaresList?.map((currSoftwareObj, idx) => (
                            <li key={idx} className='list-group-item border-0 my-1 py-1'>
                                {isEditMode ? (
                                    <label className='d-flex align-items-center mb-0'>
                                        <input
                                            className='p-0'
                                            type="checkbox"
                                            value={ProhibitedSoftwaresStore.getSoftwareNameToDisplay(currSoftwareObj, currentOs)}
                                            checked={currSoftwareObj.isChecked}
                                            onChange={() => handleCheckboxChange(currSoftwareObj.id)}
                                        />
                                        <span className='ml-2'>{ProhibitedSoftwaresStore.getSoftwareNameToDisplay(currSoftwareObj, currentOs)}</span>
                                    </label>)
                                    :
                                    <span className='ml-1'>{ProhibitedSoftwaresStore.getSoftwareNameToDisplay(currSoftwareObj, currentOs)}</span>
                                }
                            </li>
                        ))}
                    </ul>
                </div>

                {currentOs === PLATFORM.WINDOWS ? (
                    <div className='mt-4 d-flex align-items-center new-software-win-input-section'>
                        <input value={newSoftwaresForWin} onChange={(e) => setNewSoftwaresForWin(e.target.value)} type="text" className='bg-white border border-quill-lightest rounded-sm py-2 pl-2 flex-grow-1' placeholder='Enter comma separated software names'/>
                        <Button variant="light" className='bg-white border-quill-lightest rounded-sm py-2 ml-2 mx-2' onClick={handleSaveNewSoftwares} disabled={loadingState.status && loadingState.type === LOADING_STATE.SAVE}>{(loadingState.status && loadingState.type === LOADING_STATE.SAVE) ? "Saving..." : "Save"}</Button>
                    </div>
                )
                :
                (
                    <div>
                        <p className='mt-4 mb-0'><b>Add Prohibited Software</b></p>
                        <p>Please enter Software name and the process name and click on <b>SAVE</b> button to add an entry of prohibited software</p>
                        <div className='d-flex align-items-center'>
                            <div className='d-flex align-items-center'>
                                <span>Software Name</span>
                                <input value={newSoftwaresForMac.name} onChange={(e) => setNewSoftwaresForMac({...newSoftwaresForMac, "name": e.target.value})} type="text" className='bg-white border border-quill-lightest rounded-sm py-2 pl-2 ml-2 new-software-mac-input' placeholder='Software_name' maxLength={255}/>
                            </div>
                            <div className='d-flex align-items-center ml-4'>
                                <span>Software Process Name</span>
                                <input value={newSoftwaresForMac.executable} onChange={(e) => setNewSoftwaresForMac({...newSoftwaresForMac, "executable": e.target.value})} type="text" className='bg-white border border-quill-lightest rounded-sm py-2 pl-2 ml-2 new-software-mac-input' placeholder='Software_process' maxLength={255}/>
                            </div>
                            <Button variant="light" className='bg-white border-quill-lightest rounded-sm py-2 ml-4 px-2' onClick={handleSaveNewSoftwares} disabled={loadingState.status && loadingState.type === LOADING_STATE.SAVE}>{(loadingState.status && loadingState.type === LOADING_STATE.SAVE) ? "Saving..." : "Save to list"}</Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ProhibitedSoftwares;