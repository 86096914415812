import React, { useState, useEffect } from 'react';

import { Table, Form, Button } from 'react-bootstrap';
import { AiOutlineHome, AiOutlineArrowLeft } from "react-icons/ai";
import MpaasAdminPanelApi from '../../utils/MpaasAdminPanelApi';
import Logger from "../../utils/Logger";
import loaderGif from "../../images/gif/loader.gif";
import { FaArrowDown, FaAsterisk, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import DOMPurify from 'dompurify';

const ViewCandidateProctoringStatistics: React.FC = () => {
    const [clientId, setClientId] = useState("");
    const [gids, setGids] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [currentGid, setCurrentGid] = useState("");
    const [totalCandidatesInCurrentGroup, setTotalCandidatesInCurrentGroup] = useState(0);
    const [errorMsg, bindError] = useState("");
    const [errorToast, setToast] = useState(false);
    const [groupTableView, setGroupTableView] = useState(false);
    const [candidateTableView, setCandiadteTableView] = useState(false);
    const [showLoader, setshowLoader] = useState(false);
    const [formView, setFormView] = useState(true);
    const [groupProctoringStatisticsData, setGroupProctoringStatisticsData] = useState({} as any);
    const [candidateProctoringStatisticsData, setCandidateProctoringStatisticsData] = useState({} as any);
    let [acitivePaginationIndex, setacitivePaginationIndex] = useState(1);
    let [totalPaginationIndex, setTotalPaginationIndex] = useState(1);
    const tablePageSize: number = 30;

    const _setStartDateAndEndDate = () => {
        let maxDate = new Date();
        maxDate.setDate(maxDate.getDate() - 1);
        setMaxDate(_formatDate(maxDate));

        let minDate = new Date();
        minDate.setDate(minDate.getDate() - 7);
        setMinDate(_formatDate(minDate));
    }

    const _formatDate = (date: any) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const [maxDate, setMaxDate] = useState(_formatDate(new Date()));
    const [minDate, setMinDate] = useState(_formatDate(new Date()));

    const _getGroupProctoringStatistics = () => {
        if (clientId == "") {
            bindError("Please enter valid Client ID");
            _toastbar();
        } else if (startDate == "" || endDate == "") {
            bindError("Please enter both Start date and End date");
            _toastbar();
        } else {
            var start = new Date(startDate);
            var end = new Date(endDate);

            if (start > end) {
                bindError("Please enter valid Start date and End date interval");
                _toastbar();
                return;
            }

            setshowLoader(true);
            setGroupTableView(false);
            setCandiadteTableView(false);
            setFormView(false);

            let payload = {
                clientId: clientId,
                startDate: startDate,
                endDate: endDate,
                groupIds: gids
            }

            MpaasAdminPanelApi.getGroupProctoringStatistics(payload).then((response: any) => {
                setshowLoader(false);
                if (response.groupProctoringStatistics.length == 0) {
                    bindError("No Results found");
                    _toastbar();
                    setFormView(true);
                } else if (response.groupProctoringStatistics.length > 0) {
                    _setGroupProctoringStatistics(response);
                }
            }, (error) => {
                setshowLoader(false);
                bindError(error.message);
                _toastbar();
                setFormView(true);
                Logger.error("error", error);
            });
        }
    }

    const _getCandidateProctoringStatistics = (gid: any, totalCandidateCount: any) => {
        setCurrentGid(gid);
        setTotalCandidatesInCurrentGroup(totalCandidateCount);
        setTotalPaginationIndex(Math.ceil(totalCandidateCount / tablePageSize));
        if (clientId == "") {
            bindError("Please enter valid Client ID");
            _toastbar();
        } else if (startDate == "" || endDate == "") {
            bindError("Please enter both Start date and End date");
            _toastbar();
        } else {
            var start = new Date(startDate);
            var end = new Date(endDate);

            if (start > end) {
                bindError("Please enter valid Start date and End date interval");
                _toastbar();
                return;
            }

            setshowLoader(true);
            setGroupTableView(false);
            setCandiadteTableView(false);
            setFormView(false);

            let payload = {
                clientId: clientId,
                startDate: startDate,
                endDate: endDate,
                groupId: gid,
                pageSize: tablePageSize,
                pageNo: acitivePaginationIndex
            }

            MpaasAdminPanelApi.getCandidateProctoringStatistics(payload).then((response: any) => {
                setshowLoader(false);
                if (response.status == "ERROR") {
                    bindError(response.error.message);
                    _toastbar();
                    setFormView(true);
                } else if (response.candidateProctoringStatistics.length > 0) {
                    _setCandidateProctoringStatistics(response);
                }
            }, (error) => {
                setshowLoader(false);
                bindError(error.message);
                _toastbar();
                setFormView(true);
                Logger.error("error", error);
            });
        }
    }

    const _downloadAllGroupProctoringStatistics = () => {
        let payload = {
            clientId: clientId,
            startDate: startDate,
            endDate: endDate,
            groupIds: gids
        }

        MpaasAdminPanelApi.downloadGroupProctoringStatistics(payload);
    }

    const _downloadAllCandidateProctoringStatistics = () => {
        let payload = {
            clientId: clientId,
            startDate: startDate,
            endDate: endDate,
            groupId: currentGid
        }

        MpaasAdminPanelApi.downloadCandidateProctoringStatistics(payload);
    }

    const _toastbar = () => {
        setToast(true);
        setTimeout(function () {
            setToast(false);
        }, 4000);
    }

    const _setGroupProctoringStatistics = (response: any) => {
        setGroupProctoringStatisticsData(response.groupProctoringStatistics);
        setGroupTableView(true);
        setCandiadteTableView(false);
        setFormView(false);
    }

    const _setCandidateProctoringStatistics = (response: any) => {
        setCandidateProctoringStatisticsData(response.candidateProctoringStatistics);
        setGroupTableView(false);
        setCandiadteTableView(true);
        setFormView(false);
    }

    const goBackToForm = () => {
        setGroupTableView(false);
        setFormView(true);
        setacitivePaginationIndex(1);
        setshowLoader(false);
    }

    const goBackToGroupView = () => {
        setCandiadteTableView(false)
        setGroupTableView(true);
        setFormView(false);
        setacitivePaginationIndex(1);
        setshowLoader(false);
        setCurrentGid("");
        setTotalCandidatesInCurrentGroup(0);
    }

    const paginationView = (isNext: boolean) => {
        if (isNext) {
            setacitivePaginationIndex(++acitivePaginationIndex);
        } else {
            setacitivePaginationIndex(--acitivePaginationIndex);
        }

        _getCandidateProctoringStatistics(currentGid, totalCandidatesInCurrentGroup);
    }

    useEffect(() => {
        _setStartDateAndEndDate();
    }, []);

    return (
        <>
            <div className='breadcrumb-section'>
                <AiOutlineHome /> <span className="small"> &gt; <span className="text-muted">View Candidate Proctoring Statistics</span> </span>
            </div>
            {
                errorToast ?
                    <div className='toast-bar'>
                        <span>{errorMsg}</span>
                    </div> : ''
            }
            {
                formView ?
                    <><div className='candidate-proctoring-statistics-form'>
                        <h4 className="title">View Candidate Proctoring Statistics</h4>
                        <Form>
                            <Form.Group className="mb-3" controlId="formPid">
                                <Form.Label>Client Id <FaAsterisk className='text-danger mb-2' size={10} /></Form.Label>
                                <Form.Control type="number" value={clientId} placeholder="Enter Client Id" onChange={(e) => setClientId(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formSd">
                                <Form.Label>Start Date <FaAsterisk className='text-danger mb-2' size={10} /></Form.Label>
                                <Form.Control type="date" value={startDate} min={minDate} max={maxDate} onChange={(e) => setStartDate(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEd">
                                <Form.Label>End Date <FaAsterisk className='text-danger mb-2' size={10} /></Form.Label>
                                <Form.Control type="date" value={endDate} min={minDate} max={maxDate} onChange={(e) => setEndDate(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGid">
                                <Form.Label>Group Id  (Comma Separated)</Form.Label>
                                <Form.Control type="string" value={gids} placeholder="Enter Group Ids" onChange={(e) => setGids(e.target.value)} />
                            </Form.Group>
                            <Button variant="primary" type="button" onClick={() => _getGroupProctoringStatistics()}>
                                Submit
                            </Button>
                        </Form>
                    </div></>
                    : ''
            }

            {
                showLoader ?
                    <div className="text-center mt-5 pt-5">
                        <img src={loaderGif} alt="loading" height="150px" width="150px" />
                    </div>
                    : ""
            }

            {
                groupTableView ?
                    <>
                        <h4 className="title">View Candidate Proctoring Statistics</h4>
                        <Button variant="link" className="float-left py-0" onClick={() => goBackToForm()}><AiOutlineArrowLeft /> Back</Button>
                        <Button variant="link" className="float-right py-0" onClick={() => _downloadAllGroupProctoringStatistics()}><FaArrowDown /> Download All</Button>

                        <div className="Tableview mt-5">
                            <Table bordered responsive className="text-nowrap">
                                <thead className="bg-sky-lighter">
                                    <tr>
                                        <th>Client ID</th>
                                        <th>Group ID</th>
                                        <th>Group Name</th>
                                        <th>Total Candidate Count</th>
                                        <th>Proctoring Duration</th>
                                        <th>Video Recording Duration</th>
                                        <th>Number of Video Files</th>
                                        <th>View Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groupProctoringStatisticsData.length > 0 && groupProctoringStatisticsData.map((value: any, key: any) => {
                                        return (
                                            <tr key={key}>
                                                <td>{value.clientId}</td>
                                                <td>{value.groupId}</td>
                                                <td>{value.groupName}</td>
                                                <td>{value.totalCandidateCount}</td>
                                                <td>{value.totalProctoringDurationInTest}</td>
                                                <td>{value.totalVideoRecordingDurationInTest}</td>
                                                <td>{value.totalNumberOfVideoFilesInTest}</td>
                                                <td><Button variant="link" onClick={() => { _getCandidateProctoringStatistics(value.groupId, value.totalCandidateCount) }}>CTA</Button></td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </>
                    : ''
            }

            {
                candidateTableView ?
                    <>
                        <h4 className="title">View Candidate Proctoring Statistics for Group Id {currentGid}</h4>
                        <Button variant="link" className="float-left py-0" onClick={() => goBackToGroupView()}><AiOutlineArrowLeft /> Back</Button>
                        <Button variant="link" className="float-right py-0" onClick={() => _downloadAllCandidateProctoringStatistics()}><FaArrowDown /> Download All</Button>

                        <div className="Tableview mt-5">
                            <Table bordered responsive className="text-nowrap">
                                <thead className="bg-sky-lighter">
                                    <tr>
                                        <th>Proctoring Instance Id</th>
                                        <th>User Id</th>
                                        <th>Is Full Session Recording On ?</th>
                                        <th>Is Audio Proctoring On ?</th>
                                        <th>Proctoring Duration</th>
                                        <th>Video Recording Duration</th>
                                        <th>Number of Video Files</th>
                                        <th>Public Report URL</th>
                                        <th>Private Report URL</th>
                                        <th>Video File Number_Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {candidateProctoringStatisticsData.length > 0 && candidateProctoringStatisticsData.map((value: any, key: any) => {
                                        return (
                                            <tr key={key}>
                                                <td>{value.proctoringInstanceId}</td>
                                                <td>{value.userId}</td>
                                                <td>{value.isFullSessionRecordingOn ? "Yes" : "No"}</td>
                                                <td>{value.isAudioProctoringOn ? "Yes" : "No"}</td>
                                                <td>{value.proctoringDurationInTest}</td>
                                                <td>{value.videoRecordingDurationInTest}</td>
                                                <td>{value.numberOfVideoFilesInTest}</td>
                                                <td><a target="_blank" href={DOMPurify.sanitize(value.publicReportURL)}>Go To Public Report URL</a></td>
                                                <td><a target="_blank" href={DOMPurify.sanitize(value.privateReportURL)}>Go To Private Report URL</a></td>
                                                <td>{value.videoFileNumberDurationInTest}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </Table>
                            <div className="text-center">
                                <Button variant="link" disabled={acitivePaginationIndex === 1} onClick={() => { paginationView(false) }}><FaChevronLeft className="align-text-bottom" /> Prev</Button>
                                <Button variant="link" disabled={acitivePaginationIndex === totalPaginationIndex} onClick={() => { paginationView(true) }}>Next <FaChevronRight className="align-text-bottom" /></Button>
                            </div>
                        </div>
                    </>
                    : ''
            }
        </>
    )
}

export default ViewCandidateProctoringStatistics;